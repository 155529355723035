import React, { Component } from 'react';

import { Typography} from '@mui/material';
import moment from "moment";

// Form Validation and Dynamic Forms
import { DynamicForm, DynamicTableForm } from 'components/Forms';

import { labAnalysisReportFieldsLvannamei, labAnalysisReportFieldsMonodon } from '../booking-reports.fields';

import { 
  createNewBookingReport, updateBookingReport,fetchTotalMortalitiesByBooking 
} from 'services/booking-reports';

export default class LabAnalysisReport extends Component {
  
  state = { liveBsData : false };
  
  constructor ( props ) {
    super (props);

    localStorage.setItem('arrivaldate', JSON.stringify(this.props.bookingData.arrivalReport));
  }

  async componentDidMount() {
    try {
      const speciesDetails = this.props.bookingData.speciesType;
      const opcode = 'booking-reports:' +
      (
        this.props.currentValues
          ? ('update-lab-analysis-report:' + this.props.currentValues.id) 
          : 'create-lab-analysis-report'
      );
        
      const mortalityReport = await fetchTotalMortalitiesByBooking(this.props.bookingData.id, opcode);
      
      // auto populate data
      if (mortalityReport && mortalityReport.data) {
        const arrivalDate = new Date(mortalityReport.data.arrivingDateTime);
        let qrntDate = new Date(mortalityReport.data.arrivingDateTime);
        
        qrntDate.setDate(qrntDate.getDate() + (speciesDetails.duration - 1));

        if (speciesDetails.name.toLowerCase().includes('vannamei')) {
          labAnalysisReportFieldsLvannamei[3].default = qrntDate.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'});
          labAnalysisReportFieldsLvannamei[4].default = mortalityReport.data.totalBags;
          labAnalysisReportFieldsLvannamei[6].default = mortalityReport.data.totalArrivedBs;
          labAnalysisReportFieldsLvannamei[7].default = mortalityReport.data.totalArrivMortalities;
          labAnalysisReportFieldsLvannamei[8].default = `${this.props.bookingData.supplierDetails.name}, ${this.props.bookingData.supplierDetails.country}`;
          labAnalysisReportFieldsLvannamei[9].default = speciesDetails.duration;
          labAnalysisReportFieldsLvannamei[10].default = arrivalDate.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'});
          labAnalysisReportFieldsLvannamei[11].default = qrntDate.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'});
          labAnalysisReportFieldsLvannamei[12].default = mortalityReport.data.totalMortality;

          labAnalysisReportFieldsLvannamei[15].default = mortalityReport.data.TSV;
          labAnalysisReportFieldsLvannamei[16].default = mortalityReport.data.YHV;
          labAnalysisReportFieldsLvannamei[17].default = mortalityReport.data.IMNV;
          labAnalysisReportFieldsLvannamei[18].default = mortalityReport.data.IHHNV;
          labAnalysisReportFieldsLvannamei[19].default = mortalityReport.data.WSSV;
          labAnalysisReportFieldsLvannamei[20].default = mortalityReport.data.NHPB;
          labAnalysisReportFieldsLvannamei[21].default = mortalityReport.data.AHPND;
          labAnalysisReportFieldsLvannamei[22].default = mortalityReport.data.EHP;
          labAnalysisReportFieldsLvannamei[23].default = mortalityReport.data.DIV1SHIV;
          labAnalysisReportFieldsLvannamei[24].default = mortalityReport.data.TPD;
          labAnalysisReportFieldsLvannamei[5].default = mortalityReport.data.referenceNo;
          labAnalysisReportFieldsLvannamei[13].default = mortalityReport.data.animalAge;
          labAnalysisReportFieldsLvannamei[14].default = mortalityReport.data.aqcsCertNo;

          if (!this.props.currentValues)
          {
            //Disable Quarantine from date,to date and days field in create report
            labAnalysisReportFieldsLvannamei[9].properties.push({ readOnly: true });
            labAnalysisReportFieldsLvannamei[10].properties.push({ readOnly: true });
            labAnalysisReportFieldsLvannamei[11].properties.push({ readOnly: true });

            labAnalysisReportFieldsLvannamei[3].callback=this.handleClearanceDateChange;

          }
          labAnalysisReportFieldsLvannamei[28].callback=this.handleTestResultChange;

          labAnalysisReportFieldsLvannamei[15].properties.push({ readOnly: true }); 
          labAnalysisReportFieldsLvannamei[16].properties.push({ readOnly: true }); 
          labAnalysisReportFieldsLvannamei[17].properties.push({ readOnly: true });
          labAnalysisReportFieldsLvannamei[18].properties.push({ readOnly: true }); 
          labAnalysisReportFieldsLvannamei[19].properties.push({ readOnly: true }); 
          labAnalysisReportFieldsLvannamei[20].properties.push({ readOnly: true });
          labAnalysisReportFieldsLvannamei[21].properties.push({ readOnly: true }); 
          labAnalysisReportFieldsLvannamei[22].properties.push({ readOnly: true }); 
          labAnalysisReportFieldsLvannamei[23].properties.push({ readOnly: true });
          labAnalysisReportFieldsLvannamei[24].properties.push({ readOnly: true });

          labAnalysisReportFieldsLvannamei[28].default = mortalityReport.data.TESTRESULT;
          if (mortalityReport.data.TESTRESULT=="POSITIVE")
          {
            labAnalysisReportFieldsLvannamei[25].default = false;
          } 
          else if (mortalityReport.data.TESTRESULT=="NEGATIVE")
          {
            labAnalysisReportFieldsLvannamei[25].default = true;
          }


          if (speciesDetails.type=="PPL")
          {
            labAnalysisReportFieldsLvannamei[25].label="PPL Dispatch"
          }
        } else {
          

          labAnalysisReportFieldsMonodon[3].default = qrntDate.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'});
          labAnalysisReportFieldsMonodon[4].default = mortalityReport.data.totalBags;
          labAnalysisReportFieldsMonodon[6].default = mortalityReport.data.totalArrivedBs;
          labAnalysisReportFieldsMonodon[7].default = mortalityReport.data.totalArrivMortalities;
          labAnalysisReportFieldsMonodon[8].default = `${this.props.bookingData.supplierDetails.name}, ${this.props.bookingData.supplierDetails.country}`;
          labAnalysisReportFieldsMonodon[9].default = speciesDetails.duration;
          labAnalysisReportFieldsMonodon[10].default = arrivalDate.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'});
          labAnalysisReportFieldsMonodon[11].default = qrntDate.toLocaleDateString('en-CA', {timezone : 'Asia/Kolkata'});
          labAnalysisReportFieldsMonodon[12].default = mortalityReport.data.totalMortality;

          labAnalysisReportFieldsMonodon[15].default = mortalityReport.data.TSV;
          labAnalysisReportFieldsMonodon[16].default = mortalityReport.data.YHV;
          labAnalysisReportFieldsMonodon[17].default = mortalityReport.data.IMNV;
          labAnalysisReportFieldsMonodon[18].default = mortalityReport.data.IHHNV;
          labAnalysisReportFieldsMonodon[19].default = mortalityReport.data.WSSV;
          labAnalysisReportFieldsMonodon[20].default = mortalityReport.data.NHPB;
          labAnalysisReportFieldsMonodon[21].default = mortalityReport.data.AHPND;
          labAnalysisReportFieldsMonodon[22].default = mortalityReport.data.EHP;
          labAnalysisReportFieldsMonodon[23].default = mortalityReport.data.DIV1SHIV;
          labAnalysisReportFieldsMonodon[24].default = mortalityReport.data.MBV;
          labAnalysisReportFieldsMonodon[25].default = mortalityReport.data.MRNV;
          labAnalysisReportFieldsMonodon[26].default = mortalityReport.data.LSNV;
          labAnalysisReportFieldsMonodon[27].default = mortalityReport.data.TPD;

          labAnalysisReportFieldsMonodon[5].default = mortalityReport.data.referenceNo;
          labAnalysisReportFieldsMonodon[13].default = mortalityReport.data.animalAge;
          labAnalysisReportFieldsMonodon[14].default = mortalityReport.data.aqcsCertNo;

          if (!this.props.currentValues)
          {
            //Disable Quarantine from date,to date and days field in create report
            labAnalysisReportFieldsMonodon[9].properties.push({ readOnly: true });
            labAnalysisReportFieldsMonodon[10].properties.push({ readOnly: true });
            labAnalysisReportFieldsMonodon[11].properties.push({ readOnly: true });

            labAnalysisReportFieldsMonodon[3].callback=this.handleClearanceDateChange;
          }

          labAnalysisReportFieldsMonodon[31].callback=this.handleTestResultChange;

          labAnalysisReportFieldsMonodon[15].properties.push({ readOnly: true }); 
          labAnalysisReportFieldsMonodon[16].properties.push({ readOnly: true }); 
          labAnalysisReportFieldsMonodon[17].properties.push({ readOnly: true });
          labAnalysisReportFieldsMonodon[18].properties.push({ readOnly: true }); 
          labAnalysisReportFieldsMonodon[19].properties.push({ readOnly: true }); 
          labAnalysisReportFieldsMonodon[20].properties.push({ readOnly: true });
          labAnalysisReportFieldsMonodon[21].properties.push({ readOnly: true }); 
          labAnalysisReportFieldsMonodon[22].properties.push({ readOnly: true }); 
          labAnalysisReportFieldsMonodon[23].properties.push({ readOnly: true });
          labAnalysisReportFieldsMonodon[24].properties.push({ readOnly: true });
          labAnalysisReportFieldsMonodon[25].properties.push({ readOnly: true });
          labAnalysisReportFieldsMonodon[26].properties.push({ readOnly: true });
          labAnalysisReportFieldsMonodon[27].properties.push({ readOnly: true });

          labAnalysisReportFieldsMonodon[31].default = mortalityReport.data.TESTRESULT;
          if (mortalityReport.data.TESTRESULT=="POSITIVE")
          {
            labAnalysisReportFieldsMonodon[28].default = false;
          }
          else if (mortalityReport.data.TESTRESULT=="NEGATIVE")
          {
            labAnalysisReportFieldsMonodon[28].default = true;
          }

          if (speciesDetails.type=="PPL")
          {
            labAnalysisReportFieldsMonodon[28].label="PPL Dispatch"
          }
        }
        this.setState({liveBsData : true});
      }
    } catch (error) {
      console.log(error);
      this.setState({liveBsData : true});
    }
  }

  handleTestResultChange = async (name, value, formData, asyncFn) => {

    if (value=="POSITIVE")
      {
        formData.bsDespatch = false;
      }
      else if (value=="NEGATIVE")
      {
        formData.bsDespatch = true;
      }

    return { fields : null, values : formData };
  }
  handleClearanceDateChange = async (name, value, formData, asyncFn) => {

    let diff_days = moment(value).diff(moment(formData.qrtnFromDate), 'days');

    formData.qrtnToDate = value;
    formData.qrtnDays = (diff_days+1);

    return { fields : null, values : formData };
  }
  // Form is handled by dynamic forms, always return data as
  // JSON : { status : "success"/"error", message : ".....", data: {optional errors} }
  //
  handleFormSubmit = async (params) => {
    let statusMessage = { status: "success", message: "Successfully added new lab analysis report for the booking..." };
    params.bookingId = this.props.bookingData.id;
    
    try {
      let response;
      if (this.props.currentValues) {
        response = await updateBookingReport('lab-analysis-report', this.props.currentValues.id, params);  
        statusMessage = { status: "success", message: "Successfully updated lab analysis report for the booking..." };
      } else {
        response = await createNewBookingReport('lab-analysis-report', params);
      }
      
      if (this.props.handleFormSubmit) {
        this.props.handleFormSubmit(statusMessage);
      }
    
    } catch(error) {
      console.log(error);
      statusMessage = { status: "error", message: error.message, data : error.data };
    }
    
    return statusMessage;
  }
  
  render () {
    
    let currentValues = this.props.currentValues;
    if (currentValues) {
      // update cubicle info
      currentValues.cubicleId = currentValues.number;
    }
    
    return(
      <>
        <Typography variant="h5" align="center" p={2} >
        { 
          (this.props.currentValues ? 'Update Lab Analysis Report' : 'Lab Analysis Report') + 
          ` Booking ID : ${this.props.bookingData.id}, Hatchery : ${this.props.bookingData.hatcheryId.name}`
        }
        </Typography><hr style={{borderColor:"#eef1f6"}} />
        {
          this.state.liveBsData &&
          <DynamicForm
            formFields={
              this.props.bookingData.speciesType.name.toLowerCase().includes('vannamei')
                ? labAnalysisReportFieldsLvannamei 
                : labAnalysisReportFieldsMonodon
            }
            action={this.props.currentValues ? "update" : "create"}
            handleSubmit={this.handleFormSubmit}
            buttonText="Save Lab Analysis Report"
            buttonWidth= "full"
            columns = {4}
            currentValues={currentValues}
            validateOnLoad={true}
            redirect={this.props.redirectUrl ? this.props.redirectUrl : "/aqf/bookings/test-reports/lab-analysis-report"}
          />
        }         
      </>
    );
  }
}
